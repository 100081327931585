import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

export type Props = HTMLAttributes<HTMLDivElement>;
const HeroArea: FC<Props> = ({ ...props }) => {
  return (
    <div {...props}>
      <picture>
        <source media="(max-width: 960px)" srcSet="/image/corporate/hero-sp.webp" />
        <MaskedImage src="/image/corporate/hero-pc.webp" alt="アニメふるさと納税" width="100%" decoding="async" />
      </picture>
    </div>
  );
};
export default HeroArea;

const MaskedImage = styled.img`
  mask-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  //mask-image: radial-gradient(ellipse 70% 70% at 50% 50%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
`;
